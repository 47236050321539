var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"top-row-section py-2"},[(_vm.modalShow)?_c('IodReportPopup',{attrs:{"modalShow":_vm.modalShow,"lensOrderId":_vm.lensOrderId,"reserved":_vm.reserved},on:{"update:modalShow":function($event){_vm.modalShow=$event},"update:modal-show":function($event){_vm.modalShow=$event}}}):_vm._e(),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{staticClass:"mt-2"},[_c('h5',{staticClass:"heavy d-inline mr-1"},[_vm._v(_vm._s(_vm.t('orders')))]),_c('span',{staticClass:"text-gray-dark"},[_vm._v(" "+_vm._s(_vm.t('patientDetailsViewingAllOrders'))+" ")])])],1),_c('b-table',{ref:"ordersTableContainer",staticClass:"no-outer-padding order-card-table",attrs:{"fields":_vm.fields,"items":_vm.orderedLenses,"sticky-header":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({width: _vm.colWidths[field.key]})})})}},{key:"cell(updated)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.value))+" ")]}},{key:"cell(ocosSalesOrderId)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                    name: 'OrderDetails',
                    params: {orderId: item.orderHeaderId.toString()},
                }}},[_vm._v(_vm._s(value))])]}},{key:"cell(targetLensDescription)",fn:function(ref){
                var item = ref.item;
                var value = ref.value;
return [_c('p',{staticClass:"text-nowrap text-caption heavy text-black mb-1 pl-0 ml-0"},[_c('b-img',{staticClass:"pr-1",attrs:{"src":_vm.imageSrc(item.opEye)}}),_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter(value.model, _vm.decimalSeparator))+" ")],1),_c('p',{staticClass:"text-caption light text-gray-dark mb-0"},[_vm._v(" "+_vm._s(_vm.lensDescriptionObjToString(value, _vm.decimalSeparator))+" ")])]}},{key:"cell(reservedLensDescription)",fn:function(ref){
                var value = ref.value;
return [_c('LensModel',{attrs:{"lensDescriptionDetails":value}}),_c('div',{staticClass:"d-flex"},[_c('LensPrescription',{attrs:{"lensDescriptionDetails":value}}),(value.serial)?_c('span',{staticClass:"text-caption light text-gray-dark mb-0 white-space-pre"},[_vm._v(" #"+_vm._s(value.serial))]):_vm._e()],1)]}},{key:"cell(IOD)",fn:function(ref){
                var item = ref.item;
return [(!item.isMto)?_c('div',{staticClass:"cell-w-buttons justify-content-end"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.iodBtnClicked(item)}}},[_vm._v(_vm._s(_vm.isSidButton(item)))])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }